export const Addins = {
  INVENTOR: 'inventor',
  REVIT: 'revit',
  FUSION: 'fusion',
} as const;

export type AddinList = (typeof Addins)[keyof typeof Addins];

export type AddinsVersionInfo = {
  [year: string]: {
    version: string;
    fileURL: string;
    filename: string;
  };
};

export type AddinsMSIVersions = {
  name: string;
  timestamp: string;
  addinType: AddinList;
  version: string;
  hostApplicationVersion?: string;
}[];

export type AddinsVersionInfoByName = {
  [addinName in AddinList]: AddinsVersionInfo;
};

export const DefaultReleaseYear: string[] = ['2024'];
