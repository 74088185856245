import { extensionName } from './utils/consts';
import { generateDomId } from './utils/common';
import { useThemeStore } from './stores/ThemeStore';
import { FormLmvElement } from './components/Form/Form.lmvElement';
import { ReleaseDetailsLmvElement } from './components/ReleaseDetails/ReleaseDetails.lmvElement';
import { getDcApiServiceInstance } from 'mid-api-services';
import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { mainStoreActions } from 'stores/MainStore';
import { ProductReleaseDataForLMVExtension } from '@mid-react-common/common';

export class InformedDesignExtension extends Autodesk.Viewing.Extension {
  productReleaseData: ProductReleaseDataForLMVExtension | undefined;
  productRelease: ProductRelease | undefined;
  subToolbar: Autodesk.Viewing.UI.ControlGroup | undefined;

  formLmvElement: FormLmvElement;
  releaseDetailsLmvElement: ReleaseDetailsLmvElement;

  constructor(viewer: any, options: any) {
    super(viewer, options);

    this.formLmvElement = new FormLmvElement(viewer);
    this.releaseDetailsLmvElement = new ReleaseDetailsLmvElement(viewer);
  }

  _validateProductReleaseData(productReleaseData: ProductReleaseDataForLMVExtension | undefined): void {
    if (
      !productReleaseData ||
      !productReleaseData.accountId ||
      !productReleaseData.projectId ||
      !productReleaseData.productId ||
      !productReleaseData.releaseNumber
    ) {
      throw new Error('Invalid product data.');
    }
  }

  _log(...args: any[]): void {
    console.log(...[`${extensionName}:`, ...args]);
  }

  // this lifecycle method is automatically called by LMV
  onToolbarCreated(toolbar: Autodesk.Viewing.UI.ToolBar): void {
    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup(generateDomId('toolbarControlGroup'));

    // add all buttons here
    this.subToolbar.addControl(this.releaseDetailsLmvElement.getButton());
    this.subToolbar.addControl(this.formLmvElement.getButton());

    toolbar.addControl(this.subToolbar);
  }

  setProductReleaseDataToFetch(productReleaseData: ProductReleaseDataForLMVExtension): void {
    this._validateProductReleaseData(productReleaseData);

    this.productReleaseData = { ...productReleaseData };
  }

  async fetchProductRelease(): Promise<void> {
    const { projectId, productId, releaseNumber } = this.productReleaseData!;
    mainStoreActions.setProductReleaseLoading(true);

    try {
      this.productRelease = await getDcApiServiceInstance().getProductRelease({
        projectId,
        productId,
        releaseNumber,
      });

      mainStoreActions.setCurrentProductRelease(this.productRelease);
      mainStoreActions.setProductReleaseLoading(false);
    } catch (error) {
      // TODO: add error handling notification here
      mainStoreActions.setProductReleaseLoadingError(true);
    }
  }

  loadProductReleaseSVF(svfUrl: string): void {
    this._validateProductReleaseData(this.productReleaseData);

    // TODO: get the SVF url from the backend based ont the product data
    this.viewer.loadModel(
      svfUrl,
      {},
      (model: any) => {
        this._log('Model loaded.', model);
      },
      (error: any) => {
        this._log('Model loading failed.', error);
      },
    );
  }

  setTheme(theme: Autodesk.Viewing.GuiViewer3D['theme']): void {
    useThemeStore.setState({ theme });
  }

  load(): boolean {
    this._log('Extension is loaded.');
    return true;
  }

  unload(): boolean {
    this.releaseDetailsLmvElement.uninitialize();
    this.formLmvElement.uninitialize();

    this._log('Extension is now unloaded.');
    return true;
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension(extensionName, InformedDesignExtension);
