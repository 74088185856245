import { create } from 'zustand';

type ThemeStore = {
  theme: Autodesk.Viewing.GuiViewer3D['theme'];
  setTheme: (newTheme: Autodesk.Viewing.GuiViewer3D['theme']) => void;
};

export const useThemeStore = create<ThemeStore>((set) => ({
  theme: 'light-theme',
  setTheme: (newTheme: Autodesk.Viewing.GuiViewer3D['theme']) => set({ theme: newTheme }),
}));
