import React from 'react';

import { Spinner, SpinnerOverlayContainer, SpinnerOverlayFiller } from './SpinnerOverlayCentered.styles';

type SpinnerOverlayProps = {
  width?: number;
};

const SpinnerOverlayCentered: React.FC<SpinnerOverlayProps> = (props) => (
  <>
    <SpinnerOverlayFiller width={props.width} />
    <SpinnerOverlayContainer width={props.width} {...props}>
      <Spinner />
    </SpinnerOverlayContainer>
  </>
);

export default SpinnerOverlayCentered;
