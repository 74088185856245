import Link, { LinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { ElementType } from 'react';

// have to manually add 'component' prop, MUI bug: https://github.com/mui/material-ui/issues/29875
type LinkButtonProps = LinkProps & {
  component?: ElementType<any, keyof JSX.IntrinsicElements>;
};

const LinkButtonBase = ({ component = 'button', underline = 'none', variant = 'body2', ...props }: LinkButtonProps) => {
  return <Link component={component} underline={underline} variant={variant} {...props} />;
};

const LinkButton = styled(LinkButtonBase, {
  shouldForwardProp: (prop) => prop !== 'leftMargin' && prop !== 'topMargin',
})<LinkButtonProps & { leftMargin?: boolean; topMargin?: boolean }>`
  ${({ leftMargin, theme }) => leftMargin && `margin-left: ${theme.var.marginBase}px;`}
  ${({ topMargin, theme }) => topMargin && `margin-top: ${theme.var.marginBase}px;`}
`;

export default LinkButton;
