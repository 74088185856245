import { DCInput, ProductRelease } from '@adsk/offsite-dc-sdk';
import { ConfigurableProductProperties, VariantFormState, VariantFormStates } from '@mid-react-common/common';
import { DCProductUIExtension } from 'mid-types';
import { isDCRfaOutput } from 'mid-utils';
import { create } from 'zustand';

export interface MainStore {
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  productReleaseLoading: boolean;
  productReleaseLoadingError: boolean;
  configurableProductProperties: ConfigurableProductProperties;
  productReleaseInputsMap: Map<string, DCInput>;
  selectedRepresentation: string | undefined;
  variantFormState: VariantFormStates;
  isFormInitializing: boolean;
}

export interface MainStoreActions {
  setCurrentProductRelease: (currentProductRelease: DCProductUIExtension<ProductRelease> | undefined) => void;
  setProductReleaseLoading: (productReleaseLoading: boolean) => void;
  setProductReleaseLoadingError: (productReleaseLoadingError: boolean) => void;
  updateConfigurableProductInput: (inputToUpdate: DCInput) => void;
  resetConfigurableProductProperties: () => void;
  initConfigurableProductProperties: () => void;
  updateConfigurableProductInputs: (inputsToUpdate: DCInput[]) => void;
  setSelectedRepresentation: (selectedRepresentation: string | undefined) => void;
  setVariantFormState: (variantFormState: VariantFormStates) => void;
  setIsFormInitializing: (isFormInitializing: boolean) => void;
}

export const useMainStore = create<MainStore>(() => ({
  currentProductRelease: undefined,
  productReleaseLoading: false,
  productReleaseLoadingError: false,
  configurableProductProperties: {
    inputs: [],
    outputs: [],
  } as ConfigurableProductProperties,
  productReleaseInputsMap: new Map<string, DCInput>(),
  selectedRepresentation: undefined,
  variantFormState: VariantFormState.DEFAULT_VARIANT,
  isFormInitializing: true,
}));

export const mainStoreActions: MainStoreActions = {
  setCurrentProductRelease: (currentProductRelease: DCProductUIExtension<ProductRelease> | undefined): void => {
    useMainStore.setState({ currentProductRelease });
    mainStoreActions.initConfigurableProductProperties();
  },
  setProductReleaseLoading: (productReleaseLoading: boolean) =>
    useMainStore.setState((state) => ({ ...state, productReleaseLoading, productReleaseLoadingError: false })),
  setProductReleaseLoadingError: (productReleaseLoadingError: boolean) =>
    useMainStore.setState((state) => ({ ...state, productReleaseLoadingError })),
  resetConfigurableProductProperties: (): void => {
    const { currentProductRelease } = useMainStore.getState();
    const rfaOutput = currentProductRelease?.outputs.find(isDCRfaOutput);

    if (currentProductRelease) {
      useMainStore.setState({
        configurableProductProperties: {
          inputs: currentProductRelease.inputs,
          outputs: currentProductRelease.outputs,
        },
        productReleaseInputsMap: new Map<string, DCInput>(currentProductRelease.inputs.map((input) => [input.name, input])),
        selectedRepresentation: rfaOutput?.options?.modelStates?.at(0),
      });
    }
  },
  initConfigurableProductProperties() {
    mainStoreActions.resetConfigurableProductProperties();
  },
  updateConfigurableProductInput: (inputToUpdate: DCInput): void => {
    const { productReleaseInputsMap } = useMainStore.getState();
    const clonedInputsMap = new Map<string, DCInput>(productReleaseInputsMap);
    clonedInputsMap.set(inputToUpdate.name, inputToUpdate);

    useMainStore.setState((state) => ({
      productReleaseInputsMap: clonedInputsMap,
      configurableProductProperties: {
        ...state.configurableProductProperties,
        inputs: [...clonedInputsMap.values()],
      },
    }));
  },
  updateConfigurableProductInputs: (inputsToUpdate: DCInput[]): void => {
    const inputMaps = inputsToUpdate.reduce((acc: Map<string, DCInput>, input: DCInput) => {
      acc.set(input.name, input);
      return acc;
    }, new Map<string, DCInput>());

    useMainStore.setState((state) => ({
      productReleaseInputsMap: inputMaps,
      configurableProductProperties: {
        ...state.configurableProductProperties,
        inputs: [...inputMaps.values()],
      },
    }));
  },
  setSelectedRepresentation: (selectedRepresentation: string | undefined): void =>
    useMainStore.setState({ selectedRepresentation }),
  setVariantFormState: (variantFormState: VariantFormStates): void => useMainStore.setState({ variantFormState }),
  setIsFormInitializing: (isFormInitializing: boolean): void => useMainStore.setState({ isFormInitializing }),
};
