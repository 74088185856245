import { GenerateOutputsResult, PublisherInput, PublisherOutput } from '../../interfaces/publisherAutomation';
import { InventorProperties } from '../../interfaces/inventorProperties';
import { logError } from 'mid-utils';
import { browserApiService } from '../../services';
import { FusionActiveDocumentInfo, InventorActiveDocumentInfo } from 'mid-types';
import { RevitClassification } from '../../interfaces/revitClassification';
import { PublishChecks } from '../../interfaces/publishChecks';

export const getPartOrAssemblyProperties = async (path: string): Promise<InventorProperties> => {
  const result = await browserApiService.getPartOrAssemblyProperties(path);
  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const getModelStates = async (documentPath: string): Promise<string[]> => {
  const modelStatesResult = await browserApiService.getModelStates(documentPath);
  if (modelStatesResult.value === null) {
    throw new Error(`${modelStatesResult.errorMessage}`);
  }
  return modelStatesResult.value;
};

export const generateOutputs = async (
  topFolderPath: string,
  documentPath: string,
  inputs: PublisherInput[],
  requestedOutputs: PublisherOutput[],
): Promise<GenerateOutputsResult> => {
  const inputsJson = JSON.stringify(inputs);
  const requestedOutputsJson = JSON.stringify(requestedOutputs);
  const generateOutputsResult = await browserApiService.generateOutputs(
    topFolderPath,
    documentPath,
    inputsJson,
    requestedOutputsJson,
  );

  return generateOutputsResult;
};

export const openProductDefinitionDocument = async (documentPath: string, inputs: PublisherInput[]): Promise<boolean> => {
  const openStatus = await browserApiService.isDocumentOpenInTheEditor(documentPath);
  if (!openStatus) {
    return false;
  }

  const inputsJson = JSON.stringify(inputs);

  const result = await browserApiService.openProductDefinitionDocument(documentPath, inputsJson);
  if (!result.value) {
    logError(`${result.errorMessage}`);
    return false;
  }
  return true;
};

export const isDocumentOpenInTheEditor = async (documentPath: string): Promise<boolean> => {
  const result = await browserApiService.isDocumentOpenInTheEditor(documentPath);
  if (!result.value) {
    if (result.errorMessage) {
      logError(`${result.errorMessage}`);
    }
    return false;
  }
  return true;
};

export const getEngineVersion = async (): Promise<string> => {
  const result = await browserApiService.getApplicationVersionNumber();
  return result;
};

export const getActiveDocumentInfo = async (): Promise<InventorActiveDocumentInfo | FusionActiveDocumentInfo> => {
  if (!browserApiService.getActiveDocumentInfo) {
    logError('getActiveDocumentInfo is not implemented in host API');
  }

  const result = await browserApiService.getActiveDocumentInfo();
  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

/**
 *  Gets Revit classification object of the active part or assembly document.
 * @returns A JSON string containing Revit classification object
 */
export const getRevitClassification = async (): Promise<RevitClassification> => {
  if (!browserApiService.getRevitClassification) {
    throw new Error('getRevitClassification is not implemented in host API');
  }

  const result = await browserApiService.getRevitClassification();
  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

/**
 * Runs a series of checks on the active part or assembly document.
 */
export const runPublishChecks = async (): Promise<PublishChecks> => {
  if (!browserApiService.runPublishChecks) {
    throw new Error('runPublishChecks is not implemented in host API');
  }

  const result = await browserApiService.runPublishChecks();
  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

export const writeToPublishData = async (publishData: string): Promise<void> =>
  await browserApiService.writeToPublishData(publishData);
