import { analytics, ANALYTICS_ACTIONS, MidWebAppMoniker, Spacing } from '@mid-react-common/common';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { AddinList, Addins, AddinsVersionInfo } from 'mid-types';
import { installersTestIds } from 'tests/helpers/dataTestIds';
import text from '../../../global/text.json';
import { ButtonsWrapper, InformationWrapper } from '../Installers.styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import GatekeeperContext from 'context/GatekeeperStore/Gatekeeper.context';

interface DownloadSectionProps {
  title: string;
  information: string;
  addinVersionInfo: AddinsVersionInfo;
  productPageUrl: string;
  productName: AddinList;
  // TODO: Remove when the product center pages are ready and when no longer in beta
  hideProductCenterPages?: boolean;
}

const DownloadSection: React.FC<DownloadSectionProps> = ({
  title,
  information,
  addinVersionInfo,
  productPageUrl,
  productName,
  hideProductCenterPages,
}) => {
  const { sessionId } = useContext(GatekeeperContext);
  const handleAddinDownloadClick = (sessionId: string, year: string, version: string) => {
    // Segment event
    analytics.track(ANALYTICS_ACTIONS.MIDW.clickAddinDownload, {
      session_id: sessionId,
      sourceMoniker: MidWebAppMoniker,
      addinHostApplicationVersion: year,
      addinVersion: version,
      productName,
    });
  };

  return (
    <InformationWrapper>
      <Typography variant="h1" fontWeight="bolder" data-testid={installersTestIds.productTitle}>
        {title}
      </Typography>
      <Spacing />
      <Typography data-testid={installersTestIds.downloadInformation}>{information}</Typography>
      <Spacing />
      <ButtonsWrapper>
        {Object.entries(addinVersionInfo).map(([year, value]) => (
          <Button
            key={year}
            variant="outlined"
            component="a"
            href={value.fileURL}
            data-testid={`${year}-${installersTestIds.downloadButton}`}
            onClick={() => handleAddinDownloadClick(sessionId, year, value.version)}
          >
            {text.installers.downloadButton} {productName !== Addins.FUSION ? year : ''}
          </Button>
        ))}
        {!hideProductCenterPages && (
          <Button
            color="inherit"
            startIcon={<ArrowCircleRightOutlinedIcon />}
            component="a"
            href={productPageUrl}
            target="_blank"
            data-testid={installersTestIds.productPageLink}
          >
            {text.installers.videosAndMore}
          </Button>
        )}
      </ButtonsWrapper>
    </InformationWrapper>
  );
};

export default DownloadSection;
