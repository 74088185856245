import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const KeyValueStackItem = styled(Box)`
  margin-bottom: ${({ theme }) => theme.var.marginBase * 1.5}px;
`;

const KeyValueStackLabelBase = ({
  color = 'text.secondary',
  noWrap = true,
  variant = 'body2',
  ...props
}: TypographyProps) => {
  return <Typography color={color} noWrap={noWrap} variant={variant} {...props} />;
};

export const KeyValueStackLabel = styled(KeyValueStackLabelBase)``;

export const KeyValueStackValue = styled(Box)`
  margin-top: ${({ theme }) => theme.var.marginBase / 2}px;
`;
