import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import { ENVIRONMENT } from 'mid-types';
import { ServiceConfigMap, ServiceTypes, getReactAppEnvironment } from 'mid-utils';
import { NavLink } from 'react-router-dom';
import text from '../../common.text.json';
import {
  InventorWebComponentMoniker,
  MIDEditFormAppMoniker,
  RevitUpdateContentMoniker,
  RevitWebComponentMoniker,
  adskGeneralTermsUrl,
  informedDesignHelpProductionUrl,
  informedDesignHelpStagingUrl,
  licensingPath,
  gainsightLink,
  FusionWebComponentMoniker,
} from '../../global/constants';
import { aboutInfoMenu } from '../../global/dataTestIds';
import { AboutInfoMenuContainer, ListItemIconWrapper, StyledIconButton } from './AboutInfoMenu.styles';
import { InstallerLinks } from './InstallerLinks';
import { VersionInfoDialog } from './VersionInfoDialog/VersionInfoDialog';
import { useAboutInfoMenu } from './useAboutInfoMenu';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface AboutInfoMenuProps {
  webComponentVersion: string;
  isTargetBlank?: boolean;
  addinVersion?: string;
  desktopApplicationVersion?: string;
}

export const AboutInfoMenu: React.FC<AboutInfoMenuProps> = ({
  webComponentVersion,
  isTargetBlank,
  addinVersion,
  desktopApplicationVersion,
}) => {
  const theme = useTheme();
  const {
    anchorElement,
    isVersionInfoDialogOpen,
    handleInfoIconClick,
    handleMenuClose,
    handleVersionInfoMenuItemClick,
    handleVersionInfoDialogClose,
  } = useAboutInfoMenu();
  const currentEnv = getReactAppEnvironment();

  const { enableGainsightLink } = useFlags();

  const isAddin =
    webComponentVersion.includes(InventorWebComponentMoniker) ||
    webComponentVersion.includes(RevitWebComponentMoniker) ||
    webComponentVersion.includes(MIDEditFormAppMoniker) ||
    webComponentVersion.includes(RevitUpdateContentMoniker) ||
    webComponentVersion.includes(FusionWebComponentMoniker);

  return (
    <AboutInfoMenuContainer>
      <StyledIconButton onClick={handleInfoIconClick} data-testid={aboutInfoMenu.aboutInfoButton}>
        <HelpOutlineIcon fontSize="small" />
      </StyledIconButton>
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            minWidth: `${theme.var.wideInputControl}px`,
          },
        }}
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
      >
        <InstallerLinks
          isAddin={isAddin}
          isTargetBlank={isTargetBlank}
          currentEnv={currentEnv}
          handleMenuClose={handleMenuClose}
        />
        <ListItemButton
          component="a"
          href={currentEnv === ENVIRONMENT.PRD ? informedDesignHelpProductionUrl : informedDesignHelpStagingUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleMenuClose}
          disableRipple
          data-testid={aboutInfoMenu.informedDesignHelpPageLink}
        >
          <ListItemText>{text.aboutInfoMenu.helpPage}</ListItemText>
          <ListItemIconWrapper>
            <OpenInNewIcon fontSize="small" />
          </ListItemIconWrapper>
        </ListItemButton>
        <ListItemButton
          component="a"
          href={adskGeneralTermsUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleMenuClose}
          disableRipple
          data-testid={aboutInfoMenu.termsAndConditionsPageLink}
        >
          <ListItemText>{text.aboutInfoMenu.termsAndConditions}</ListItemText>
          <ListItemIconWrapper>
            <OpenInNewIcon fontSize="small" />
          </ListItemIconWrapper>
        </ListItemButton>
        <ListItemButton
          component={isTargetBlank ? 'a' : NavLink}
          to={isTargetBlank ? `${ServiceConfigMap[ServiceTypes.MID_WEBAPP][currentEnv].api}${licensingPath}` : licensingPath}
          target={isTargetBlank ? '_blank' : undefined}
          disableRipple
          onClick={handleMenuClose}
          data-testid={aboutInfoMenu.licensingPageLink}
        >
          <ListItemText>{text.aboutInfoMenu.licenseAttributions}</ListItemText>
          {isTargetBlank && (
            <ListItemIconWrapper>
              <OpenInNewIcon fontSize="small" />
            </ListItemIconWrapper>
          )}
        </ListItemButton>
        <ListItemButton onClick={handleVersionInfoMenuItemClick} disableRipple>
          <ListItemText>{text.aboutInfoMenu.versionInformation}</ListItemText>
        </ListItemButton>
        {enableGainsightLink && (
          <ListItemButton href={gainsightLink}>
            <ListItemText>{text.aboutInfoMenu.gainsightLink}</ListItemText>
          </ListItemButton>
        )}
      </Menu>
      <VersionInfoDialog
        isAddin={isAddin}
        isVersionInfoDialogOpen={isVersionInfoDialogOpen}
        handleVersionInfoDialogClose={handleVersionInfoDialogClose}
        webComponentVersion={webComponentVersion}
        addinVersion={addinVersion}
        desktopApplicationVersion={desktopApplicationVersion}
      />
    </AboutInfoMenuContainer>
  );
};
