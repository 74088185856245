import { useCancellablePromise } from '@mid-react-common/common';
import { GridRowId } from '@weave-mui/data-grid';
import { useThumbnail } from 'global/hooks/hooks';
import { logError } from 'mid-utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import text from '../../../global/text.json';
import { getPublishLocationPathFromFolderPath } from './ReleaseDetails.utils';
import AccountProjectContext from 'context/AccountProjectStore/AccountProject.context';
import { ACC_BRIDGE_STATUS, DCProductUIExtension } from 'mid-types';
import { getFolderUrnFromFolderPath } from '../Releases.utils';
import { DynamicContent, ProductRelease } from '@adsk/offsite-dc-sdk';

const releasesText = text.releases;

interface UseReleaseDetailsState {
  selectedIndex: number;
  parameterVisibility: boolean;
  releaseFullPath: string[] | Error;
  thumbnail: string | null;
  isThumbnailLoading: boolean;
  currentRelease: DCProductUIExtension<ProductRelease> | undefined;
  handleShowAllClick: () => void;
  handleReleaseDetailsPageChange: (index: number) => void;
}

interface UseReleaseDetailsProps {
  productReleases: DCProductUIExtension<ProductRelease>[];
  selectedProduct: DCProductUIExtension<DynamicContent> | null;
  selectedReleasesIds: GridRowId[];
}

const useReleaseDetails = ({
  productReleases,
  selectedProduct,
  selectedReleasesIds,
}: UseReleaseDetailsProps): UseReleaseDetailsState => {
  const { projectId } = useContext(AccountProjectContext);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [parameterVisibility, setParameterVisibility] = useState<boolean>(false);
  const [releaseFullPath, setReleaseFullPath] = useState<string[] | Error>([]);
  const [selectedReleases, setSelectedReleases] = useState<DCProductUIExtension<ProductRelease>[]>([]);
  const [currentRelease, setCurrentRelease] = useState<DCProductUIExtension<ProductRelease>>();

  const cancellablePromise = useCancellablePromise();

  const incomingAccBridgeDataMemo = useMemo(
    () =>
      currentRelease?.accBridgeStatus === ACC_BRIDGE_STATUS.INCOMING
        ? { sourceProjectId: currentRelease.tenancyId, targetProjectId: projectId! }
        : undefined,
    [currentRelease, projectId],
  );

  const { thumbnail, isThumbnailLoading } = useThumbnail({
    thumbnailId: currentRelease?.thumbnail,
    thumbnailProjectId: projectId!,
    incomingAccBridgeData: incomingAccBridgeDataMemo,
  });

  const handleShowAllClick = () => {
    setParameterVisibility((parameterVisibility) => !parameterVisibility);
  };

  const handleReleaseDetailsPageChange = (index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedReleases(productReleases.filter((release) => selectedReleasesIds.includes(release.release)));
  }, [productReleases, selectedReleasesIds]);

  useEffect(() => {
    if (selectedReleasesIds) {
      setCurrentRelease(selectedReleases[selectedIndex]);
    }
  }, [selectedIndex, selectedReleases, selectedReleasesIds]);

  useEffect(() => {
    const fetchReleaseFullPath = async () => {
      if (currentRelease && projectId && selectedProduct) {
        try {
          const folderUrn = selectedProduct.isFromAccBridge
            ? selectedProduct.targetFolderUrn
            : getFolderUrnFromFolderPath(selectedProduct.context.workspace.folderPath);

          const fullPath = await cancellablePromise(getPublishLocationPathFromFolderPath(projectId, folderUrn));
          setReleaseFullPath(fullPath);
        } catch (e) {
          logError({ message: releasesText.failedToFetchFolderPath, error: e });
          setReleaseFullPath(new Error(releasesText.failedToFetchFolderPath));
        }
      }
    };
    fetchReleaseFullPath();
  }, [projectId, currentRelease, setReleaseFullPath, cancellablePromise, selectedProduct]);

  // Must reset the selected index when the selected product changes or when the selected releases are empty
  useEffect(() => {
    if (selectedReleases.length === 0) {
      setSelectedIndex(0);
    }
    if (selectedReleases.length && selectedReleases.length <= selectedIndex) {
      setSelectedIndex(selectedReleases.length - 1);
    }
  }, [selectedIndex, selectedReleases.length]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [selectedProduct]);

  return {
    isThumbnailLoading,
    thumbnail,
    parameterVisibility,
    selectedIndex,
    releaseFullPath,
    currentRelease,
    handleShowAllClick,
    handleReleaseDetailsPageChange,
  };
};

export default useReleaseDetails;
