import { ReactElement, useContext, FC, StrictMode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { cssPrefix } from './consts';
import { useThemeStore } from '../stores/ThemeStore';
import { darkTheme, lightTheme } from '@mid-react-common/common/styles/midTheme';

import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotificationContext, NotificationsProvider, useNotificationStore } from '@mid-react-common/common';
import CssBaseline from '@mui/material/CssBaseline';

export const generateDomId = (id: string): string => {
  return `${cssPrefix}${id}`;
};

export const wrapStrictMode = (children: ReactElement): ReactElement => {
  return <StrictMode>{children}</StrictMode>;
};

export const wrapTheme = (children: ReactElement): ReactElement => {
  // LMV and MID themes slightly differ in names
  const lmvToIndThemeMapping = {
    'dark-theme': darkTheme,
    'light-theme': lightTheme,
  };

  const theme = lmvToIndThemeMapping[useThemeStore.getState().theme];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export const wrapReactQuery = (children: ReactElement): ReactElement => {
  const Component: FC<{ children: ReactElement }> = ({ children }): ReactElement => {
    const { logAndShowNotification } = useContext(NotificationContext);

    const queryClient = new QueryClient({
      queryCache: new QueryCache({
        onError: (error) => {
          logAndShowNotification({
            error,
          });
          console.error(error);
        },
      }),
      defaultOptions: { queries: { gcTime: 0, retry: false } },
    });

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
  };

  return <Component>{children}</Component>;
};

export const wrapNotifications = (children: ReactElement, portalNode: HTMLElement): ReactElement => {
  const Component: FC<{ children: ReactElement }> = ({ children }): ReactElement => {
    const notificationStore = useNotificationStore();

    // portal node will be the LMV container to show notifications relative to the LMV as opposed to the whole page
    return (
      <NotificationsProvider store={notificationStore} portalNode={portalNode}>
        {children}
      </NotificationsProvider>
    );
  };

  return <Component>{children}</Component>;
};

export const wrapAllReactContexts = (children: ReactElement, portalNode: HTMLElement): ReactElement => {
  return wrapStrictMode(wrapTheme(wrapNotifications(wrapReactQuery(children), portalNode)));
};
