import { OutputType } from '@adsk/offsite-dc-sdk';

export type PublisherInput = {
  name: string;
  value: string;
  isProperty: boolean;
};

const InventorOutputThumbnailType = 'Thumbnail';

export const locallyGeneratedOutputTypes = {
  [OutputType.RFA]: OutputType.RFA,
  [OutputType.BCD]: OutputType.BCD,
  [OutputType.THUMBNAIL]: InventorOutputThumbnailType,
  [OutputType.STEP]: OutputType.STEP,
} as const;
export type LocallyGeneratedOutputType = (typeof locallyGeneratedOutputTypes)[keyof typeof locallyGeneratedOutputTypes];

export type PublisherOutput = {
  type: LocallyGeneratedOutputType;
  modelStates?: string[];
};

export type OutputFileInfo = {
  type: LocallyGeneratedOutputType;
  modelState?: string;
  filePath: string;
};

export type GenerateOutputsResult = {
  success: boolean;
  outputFiles?: OutputFileInfo[];
  report: string;
};

export type UploadContentResult = {
  filePath: string;
  objectKey: string;
};
