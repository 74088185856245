import { getReactAppEnvironment, ServiceConfigMap, ServiceTypes, UserInfoError } from 'mid-utils';
import { identitySdkConfig } from './authConfig';
import authText from './auth.text.json';
import { AuthClient, AuthClientOptions } from '@adsk/identity-web-sdk';
import { AUTH_STORAGE_KEYS } from './auth.const';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { UserProfile, ExtendedUserInfo } from 'mid-types';

export class AuthService {
  public token?: string;
  public authClient!: AuthClient;

  // Initialize Auth Service must be called before any other method
  public initialize(config?: AuthClientOptions): void {
    // Instantiating the Identity Web SDK AuthClient
    this.authClient = new AuthClient(config || identitySdkConfig);
  }

  public async authenticate(): Promise<string | undefined> {
    // following https://aps.autodesk.com/en/docs/identity-web-sdk/v1/developer-guide/getting-started/identity-web-sdk-usage/
    const currentUrl = window.location.href;
    const parsedUrl = new URL(currentUrl);

    try {
      if (parsedUrl.searchParams.has(AUTH_STORAGE_KEYS.LOGIN_REDIRECT_URL_CODE)) {
        // this method will make a token call and store the token in cache
        const { accessToken } = await this.authClient.handleRedirectCallback();
        this.token = accessToken;

        // We use replaceState to remove the code from the URL instead of window.location.replace
        // This is to avoid a full page reload which causes an infinite loop in cypress tests
        parsedUrl.search = ''; // Clear the query parameters
        window.location.replace(parsedUrl.toString());
        return accessToken;
      }
      // Check User session first
      const isLoggedIn = await this.authClient.isAuthenticated();

      //if session exists, get token using getAccessTokenSilently()
      if (isLoggedIn) {
        try {
          const token = await this.authClient.getAccessTokenSilently();
          this.token = token;

          return token;
        } catch (e: unknown) {
          // Make login call if login_required error
          await this.authClient.loginWithRedirect();
        }
      } else {
        // session does not exist, redirect to login
        await this.authClient.loginWithRedirect();
      }
    } catch (e: unknown) {
      // Any error during authentication, redirect to login
      await this.authClient.loginWithRedirect();
    }
  }

  public async getOAuth2Token(): Promise<string> {
    return await this.authClient.getAccessTokenSilently();
  }

  public async getUserInfo(): Promise<ExtendedUserInfo> {
    try {
      const userInfo: UserProfile = await getForgeApiServiceInstance().getUserProfile();
      return {
        ...userInfo,
        email: userInfo.emailId,
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        profileImg50: userInfo.profileImages.sizeX50,
      };
    } catch (e: unknown) {
      throw new UserInfoError(authText.failedToGetUserInfo, { error: e });
    }
  }

  public async logout(): Promise<void> {
    const currentEnv = getReactAppEnvironment();
    return await this.authClient.logout({
      post_logout_redirect_uri: ServiceConfigMap[ServiceTypes.MID_WEBAPP][currentEnv].api,
    });
  }
}

export default new AuthService();
