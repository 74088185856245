import { PostVariantPayload } from '@adsk/offsite-dc-sdk';
import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  projectId: string;
  productId: string;
  variantPayload?: PostVariantPayload;
};

export class VariantPostError extends CustomError {
  errorCode = ErrorCode.VariantPostError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
