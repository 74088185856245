import axios from 'axios';
import { ApiError } from 'mid-utils';
import { DownloadURLPayload, DownloadUrlResponse } from '@adsk/offsite-dc-sdk';
import { DcApiService, inversifyContainer, InversifyTypes, AccBridgeDownloadUrlQueryParams } from 'mid-api-services';
import text from '../mid-addin-lib.text.json';

// TODO: These should be in mid-api-serivces
export const getContentFromObjectKey = async <T>(
  tenancyId: string,
  objectKey: string,
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams,
): Promise<T> => {
  try {
    const downloadURLPayload: DownloadURLPayload = {
      objectKey,
    };
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    const downloadURLResponse: DownloadUrlResponse = await dcApiService.downloadURL({
      projectId: tenancyId,
      downloadURLPayload,
      incomingAccBridgeData,
    });
    const axiosInstance = axios.create();
    const response = await axiosInstance.get(downloadURLResponse.signedUrl);
    return response.data as T;
  } catch (error: unknown) {
    throw new ApiError(text.apiRequestError, { error });
  }
};

export const getSignedUrlFromDownload = async (
  tenancyId: string,
  rfaOutputUrn: string,
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams,
): Promise<string> => {
  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  const downloadURLPayload = { objectKey: rfaOutputUrn };
  const downloadURLResponse: DownloadUrlResponse = await dcApiService.downloadURL({
    projectId: tenancyId,
    downloadURLPayload,
    incomingAccBridgeData,
  });
  return downloadURLResponse.signedUrl;
};
