import { NotificationContext, authService } from '@mid-react-common/common';
import { getUUID, logError } from 'mid-utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { matchPath, useNavigate, useParams } from 'react-router-dom';
import { nonProjectRoutes } from 'routes/routes';
import { DEEP_LINK_LOCAL_STORAGE_KEYS } from '../../global/constants/deepLink';
import text from '../../global/text.json';

const gatekeepStoreText = text.gatekeeperStore;

export interface UseGatekeeperStore {
  userIsLoggedIn: boolean;
  sessionId: string;
  getFreshToken: () => Promise<string>;
  handleLogout: () => void;
}

export const useGatekeeperStore = (): UseGatekeeperStore => {
  const sessionId = useMemo(() => getUUID(), []);
  const [userIsLoggedIn, setIsLoggedIn] = useState(false);
  const { logAndShowNotification } = useContext(NotificationContext);
  const { projectId: projectIdURLParam } = useParams();
  const navigate = useNavigate();
  const isNonProjectRoute = nonProjectRoutes.some((route) => matchPath(location.pathname, route.path));

  const authenticate = async (): Promise<string | undefined> => {
    try {
      await authService.initialize();
      const tokenResponse = await authService.authenticate();

      if (tokenResponse) {
        setIsLoggedIn(!!tokenResponse);
      }

      return tokenResponse;
    } catch (e) {
      logError(e);
      throw new Error(gatekeepStoreText.failedToAuthenticate);
    }
  };

  const initialize = useCallback(async (): Promise<string | undefined> => {
    try {
      return await authenticate();
    } catch (e) {
      logError(e);
      throw new Error(gatekeepStoreText.failedToInitialize);
    }
  }, []);

  // Save Pre Authentication State
  useEffect(() => {
    // We need to check if the user has tried to access a certain page and skip setting the localstorage key
    // because once the page redirects them to the forge authentication page, the localstorage key will be lost
    // and it will be set below to '/', directing them to the initial page instead of a non project-based page
    // This check will prevent that and preserve the original URL

    const savePreAuthenticationState = () => {
      // We preserve the entry URL, so it can be
      // navigated to after the Forge Auth redirect
      if (!userIsLoggedIn) {
        window.localStorage.setItem(
          DEEP_LINK_LOCAL_STORAGE_KEYS.WEBAPP_ENTRY_POINT,
          `${window.location.pathname}${window.location.search}`,
        );
      }
    };

    if (projectIdURLParam || isNonProjectRoute) {
      savePreAuthenticationState();
    }
  }, [userIsLoggedIn, projectIdURLParam, isNonProjectRoute]);

  // Initialize Authentication
  useEffect(() => {
    const initializeAuthentication = async (): Promise<void> => {
      let hasGottenToken = false;
      let initializeFailed = false;

      try {
        if (import.meta.env.VITE_CYPRESS_BUILD === 'true') {
          await authService.initialize();
          hasGottenToken = true;
          setIsLoggedIn(true);
        } else {
          const token = await initialize();
          hasGottenToken = !!token;
        }

        const appEntryURL = window.localStorage.getItem(DEEP_LINK_LOCAL_STORAGE_KEYS.WEBAPP_ENTRY_POINT);
        if (appEntryURL && hasGottenToken) {
          navigate(appEntryURL);
        }
      } catch (error) {
        initializeFailed = true;
        logAndShowNotification({ error });
      } finally {
        // we remove the entry point after it is used
        // as the app does not manage or sanity check
        // this local storage key
        if (hasGottenToken || initializeFailed) {
          window.localStorage.removeItem(DEEP_LINK_LOCAL_STORAGE_KEYS.WEBAPP_ENTRY_POINT);
        }
      }
    };

    // If has not token yet, we need to call initializeAuthentication()
    if (!userIsLoggedIn) {
      initializeAuthentication();
    }
  }, [initialize, userIsLoggedIn, logAndShowNotification, navigate, isNonProjectRoute, projectIdURLParam]);

  const getFreshToken = useCallback(async (): Promise<string> => await authService.getOAuth2Token(), []);

  const handleLogout = (): void => {
    authService.logout();
  };

  return { userIsLoggedIn, sessionId, getFreshToken, handleLogout };
};
