import { ReleaseStatus } from '@adsk/offsite-dc-sdk';
import { FC, useContext, useEffect } from 'react';
import { ProductCustomizationForm, usePrintMessageDialog } from '@mid-react-common/addins';
import { ProductCustomizationFooter, useRepresentation } from '@mid-react-common/revit-components';
import { ModalContext, NotificationContext, useProductCustomizationForm, VariantFormState } from '@mid-react-common/common';
import { useShallow } from 'zustand/react/shallow';
import { mainStoreActions, useMainStore } from 'stores/MainStore';
import Divider from '@mui/material/Divider';
import { useFormActions } from './useFormActions';
import { ProductCustomizationFormWrapper } from './FormPanel.styles';
import { MAX_INPUTS_VIEWPORT } from './constants';
import { ProductsUtils } from 'mid-addin-lib';

export const FormPanel: FC = () => {
  const { dontShowAgain, setModalState } = useContext(ModalContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);

  const { showMessageDialog } = usePrintMessageDialog();

  const {
    currentProductRelease,
    configurableProductProperties,
    selectedRepresentation,
    isFormInitializing,
    variantFormState,
  } = useMainStore(
    useShallow((state) => ({
      currentProductRelease: state.currentProductRelease,
      configurableProductProperties: state.configurableProductProperties,
      selectedRepresentation: state.selectedRepresentation,
      isFormInitializing: state.isFormInitializing,
      variantFormState: state.variantFormState,
    })),
  );
  const {
    updateConfigurableProductInputs,
    setVariantFormState,
    setIsFormInitializing,
    setSelectedRepresentation,
    resetConfigurableProductProperties,
  } = mainStoreActions;

  const inputsQty =
    configurableProductProperties.inputs.length > MAX_INPUTS_VIEWPORT
      ? MAX_INPUTS_VIEWPORT
      : configurableProductProperties.inputs.length;

  const {
    productCustomizationFormError,
    isFormDataValid,
    setIsFormDataValid,
    inputsError,
    handleInputUpdate,
    formRules,
    tabValue,
    handleTabChange,
  } = useProductCustomizationForm({
    currentProductRelease,
    configurableProductProperties,
    updateConfigurableProductInputs,
    setVariantFormState,
    logAndShowNotification,
    showMessageDialog,
    isFormInitializing,
    setIsFormInitializing,
  });

  const { handleGenerateNewVariantClick, handleResetClick } = useFormActions({
    currentProductRelease,
    configurableProductProperties,
    selectedRepresentation,
    dontShowAgain,
    setVariantFormState,
    setModalState,
    showNotification,
    setIsFormInitializing,
    resetConfigurableProductProperties,
  });

  const handleRepresentationStateChange = (): void => {
    if (setVariantFormState) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);
    }
  };

  const { rfaRepresentationDropdownItems, selectedRepresentationDropdownItem, handleRfaRepresentationSelection } =
    useRepresentation({
      configurableProductProperties,
      selectedRepresentation,
      setSelectedRepresentation,
      handleRepresentationStateChange,
    });

  // Get the rules for the current product release
  useEffect(() => {
    const updateProductReleaseWithRules = async (): Promise<void> => {
      if (currentProductRelease) {
        const productRules = await ProductsUtils.checkAndDownloadProductRulesFromKey({
          tenancyId: currentProductRelease.tenancyId,
          rulesKey: currentProductRelease.rulesKey,
        });

        mainStoreActions.setCurrentProductRelease({ ...currentProductRelease, rules: productRules });
        mainStoreActions.setIsFormInitializing(true);
      }
    };

    if (currentProductRelease && !currentProductRelease.rules) {
      updateProductReleaseWithRules();
    }
  }, [currentProductRelease]);

  if (!currentProductRelease) {
    return null;
  }

  return (
    <div>
      <ProductCustomizationFormWrapper inputsQty={inputsQty}>
        <ProductCustomizationForm
          inactive={
            isFormInitializing ||
            currentProductRelease.status === ReleaseStatus.OBSOLETE ||
            variantFormState === VariantFormState.GENERATING_NEW_VARIANT
          }
          error={productCustomizationFormError}
          inputs={configurableProductProperties.inputs}
          inputsError={inputsError}
          handleInputUpdate={handleInputUpdate}
          setIsFormDataValid={setIsFormDataValid}
          formLayoutRules={formRules}
          isFormLoading={isFormInitializing || !currentProductRelease.rules}
          isProductConfigurable={currentProductRelease.isConfigurable}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
        />
      </ProductCustomizationFormWrapper>
      <Divider />
      <ProductCustomizationFooter
        handleResetClick={handleResetClick}
        handleGenerateNewVariantClick={handleGenerateNewVariantClick}
        handleRfaRepresentationSelection={handleRfaRepresentationSelection}
        variantFormState={variantFormState}
        isFormDataValid={isFormDataValid}
        selectedRepresentationDropdownItem={selectedRepresentationDropdownItem}
        representationDropdownItems={rfaRepresentationDropdownItems}
        currentProductReleaseStatus={currentProductRelease?.status}
        isProductConfigurable={currentProductRelease.isConfigurable}
      />
    </div>
  );
};
