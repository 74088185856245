import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import text from 'global/text.json';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import useRFOModalStore from '../../../../../context/RFOModalStore/useRFOModalStore';
import { useShallow } from 'zustand/react/shallow';
import { useQuery } from '@tanstack/react-query';
import { CAN_GENERATE_OUTPUTS_KEY } from '../../../../../global/constants/reactQueryKeys';
import { getDcApiServiceInstance } from 'mid-api-services';
import { CanGenerateOutputsErrorTypes } from '@adsk/offsite-dc-sdk';
import CircularProgress from '@mui/material/CircularProgress';
import { midTheme } from '@mid-react-common/common';
import useGenerateOutputs from './useGenerateOutputs/useGenerateOutputs';
import CanGenerateErrorsTooltip from './CanGenerateErrorsTooltip';
import { useContext, useEffect } from 'react';
import CheckoutFooterSkeleton from './CheckoutSkeletons/CheckoutFooterSkeleton';

import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import GatekeeperContext from 'context/GatekeeperStore/Gatekeeper.context';

const rfoModalText = text.rfoModal;

const canGenerateOutputsCustomErrorMessage: {
  [key in CanGenerateOutputsErrorTypes]: { title: string; message: string; footerText?: string; footerLink?: string };
} = { ...text.instancePanel.canGenerateOutputsDcApiErrorMessages, ...rfoModalText.canGenerateOutputsDcApiErrorMessages };

const CheckoutStepFooter: React.FC = () => {
  const theme = useTheme();
  const { sessionId } = useContext(GatekeeperContext);
  const {
    setUploadLocationStepActive,
    isFileTypesStepCompleted,
    isUploadLocationStepCompleted,
    totalTokenCost,
    setHasCheckoutError,
    allOutputs,
    uploadLocationProject,
    uploadFolderUrn,
    isCheckoutStepLoading,
    setIsCheckoutStepLoading,
    setIsCheckoutValidationCompleted,
    isGeneratingOutputs,
  } = useRFOModalStore(
    useShallow((state) => ({
      setUploadLocationStepActive: state.setUploadLocationStepActive,
      isFileTypesStepCompleted: state.isFileTypesStepCompleted,
      isUploadLocationStepCompleted: state.isUploadLocationStepCompleted,
      totalTokenCost: state.totalTokenCost,
      setHasCheckoutError: state.setHasCheckoutError,
      allOutputs: state.allOutputs,
      uploadLocationProject: state.uploadLocationProject,
      uploadFolderUrn: state.uploadFolderUrn,
      isCheckoutStepLoading: state.isCheckoutStepLoading,
      setIsCheckoutStepLoading: state.setIsCheckoutStepLoading,
      setIsCheckoutValidationCompleted: state.setIsCheckoutValidationCompleted,
      isGeneratingOutputs: state.isGeneratingOutputs,
      setIsGeneratingOutputs: state.setIsGeneratingOutputs,
    })),
  );

  const { data: canGenerateOutputsResponse, isLoading: isCanGenerateOutputsRequestLoading } = useQuery({
    queryKey: [CAN_GENERATE_OUTPUTS_KEY],
    queryFn: async ({ signal }) => await getDcApiServiceInstance().canGenerateOutputs(totalTokenCost, signal),
  });

  const canGenerateOutputsError = canGenerateOutputsResponse?.errorType
    ? canGenerateOutputsCustomErrorMessage[canGenerateOutputsResponse?.errorType]
    : undefined;

  useEffect(() => {
    setIsCheckoutStepLoading(isCanGenerateOutputsRequestLoading);
  }, [isCanGenerateOutputsRequestLoading, setIsCheckoutStepLoading]);

  useEffect(() => {
    setHasCheckoutError(!!canGenerateOutputsError);
    setIsCheckoutValidationCompleted(!!canGenerateOutputsResponse && !canGenerateOutputsError);
  }, [
    canGenerateOutputsError,
    canGenerateOutputsResponse,
    isFileTypesStepCompleted,
    isUploadLocationStepCompleted,
    setHasCheckoutError,
    setIsCheckoutValidationCompleted,
  ]);

  const { handleGenerateOutputsButtonClick } = useGenerateOutputs({
    sessionId,
    uploadFolderUrn,
    uploadLocationProject,
  });

  const handleGenerate = () => {
    handleGenerateOutputsButtonClick(allOutputs);
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      marginInline={`${theme.var.marginBase}px`}
      gap={`${theme.var.marginBase}px`}
    >
      {isCheckoutStepLoading ? (
        <CheckoutFooterSkeleton />
      ) : (
        <>
          {canGenerateOutputsError && <CanGenerateErrorsTooltip canGenerateOutputsError={canGenerateOutputsError} />}
          <Button
            variant="text"
            startIcon={<WestOutlinedIcon />}
            onClick={setUploadLocationStepActive}
            disabled={isGeneratingOutputs}
            data-testid={rfoModalTestIds.checkoutStep.uploadLocationStepBackButton}
          >
            {rfoModalText.uploadLocation}
          </Button>
          <Button
            variant="contained"
            onClick={handleGenerate}
            disabled={
              !isFileTypesStepCompleted ||
              !isUploadLocationStepCompleted ||
              !!canGenerateOutputsError ||
              isCanGenerateOutputsRequestLoading ||
              isGeneratingOutputs
            }
            startIcon={isGeneratingOutputs && <CircularProgress size={midTheme.var.buttonSpinnerSize} />}
            data-testid={rfoModalTestIds.checkoutStep.submitRequest}
          >
            {rfoModalText.checkoutStep.submitRequest}
          </Button>
        </>
      )}
    </Box>
  );
};

export default CheckoutStepFooter;
