import { styled } from '@mui/material/styles';
import { FORM_HEADER_HEIGHT, INPUT_HEIGHT } from './constants';

export const ProductCustomizationFormWrapper = styled('div')<{ inputsQty: number }>`
  height: ${({ inputsQty }) => `${inputsQty * INPUT_HEIGHT + FORM_HEADER_HEIGHT}px`};
  overflow-y: auto;

  & .mid-product-customization-form {
    border: none;
  }
`;
