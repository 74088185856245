import { ApiServiceFactory } from 'mid-api-services';
import { Addins, AddinsMSIVersions, AddinsVersionInfoByName } from 'mid-types';
import { ServiceTypes, VersionInfoLoadError, getReactAppEnvironment, logError } from 'mid-utils';
import text from '../common.text.json';

const METADATA_FILENAME_PATH = '/metadata.json';
const currentEnv = getReactAppEnvironment();
export const getMSIVersionInfo = async (releaseYears: string[]): Promise<AddinsVersionInfoByName | null> => {
  try {
    const apiService = ApiServiceFactory.createApiService(ServiceTypes.MID_ARTIFACTS, {
      token: '',
      env: currentEnv,
      extraHeaders: {
        'Cache-Control': 'no-cache',
      },
    });
    const { response } = apiService.abortableGet(METADATA_FILENAME_PATH);
    const data: AddinsMSIVersions = (await response).data;

    return processMSIVersionInfo(data, releaseYears);
  } catch (e) {
    logError(e);
    throw new VersionInfoLoadError(text.versionInfoLoadError);
  }
};

// Iterate over release years and return the latest version for each year as AddinsVersionInfoByYear
export const processMSIVersionInfo = (data: AddinsMSIVersions, releaseYears: string[]): AddinsVersionInfoByName => {
  const artifactsUrl = ApiServiceFactory.getServiceBaseURL(ServiceTypes.MID_ARTIFACTS, currentEnv);
  return releaseYears.reduce<AddinsVersionInfoByName>(
    (acc, year) => {
      const inventorMSIVersions = data
        .filter((row) => row.addinType === Addins.INVENTOR && row.hostApplicationVersion === year)
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
      const revitMSIVersions = data
        .filter((row) => row.addinType === Addins.REVIT && row.hostApplicationVersion === year)
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
      const fusionMSIVersions = data
        .filter((row) => row.addinType === Addins.FUSION) // No years for Fusion
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

      const latestInventorMSI = inventorMSIVersions.at(-1);
      const latestRevitMSI = revitMSIVersions.at(-1);
      const latestFusionMSI = fusionMSIVersions.at(-1);

      if (latestInventorMSI) {
        acc[Addins.INVENTOR][year] = {
          version: latestInventorMSI.version,
          filename: latestInventorMSI.name,
          fileURL: artifactsUrl + '/' + latestInventorMSI.name,
        };
      }
      if (latestRevitMSI) {
        acc[Addins.REVIT][year] = {
          version: latestRevitMSI.version,
          filename: latestRevitMSI.name,
          fileURL: artifactsUrl + '/' + latestRevitMSI.name,
        };
      }
      if (latestFusionMSI) {
        acc[Addins.FUSION]['latest'] = {
          version: latestFusionMSI.version,
          filename: latestFusionMSI.name,
          fileURL: artifactsUrl + '/' + latestFusionMSI.name,
        };
      }

      return acc;
    },
    { inventor: {}, revit: {}, fusion: {} },
  );
};
