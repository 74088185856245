import App from 'App';
import ReviewPanel from 'components/OutputsPage/ReviewPanel/ReviewPanel';
import InstancesPanel from 'components/OutputsPage/InstancesPanel/InstancesPanel';
import ModelsPanel from 'components/OutputsPage/ModelsPanel/ModelsPanel';
import InventorInstallersPage from 'pages/Installers/InventorInstallers';
import RevitInstallersPage from 'pages/Installers/RevitInstallers';
import LicensesPage from 'pages/Licencing/LicensingPage';
import { PageContainer } from 'pages/pages.styles';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import ReleasesPage from 'pages/ReleasesPage/ReleasesPage';
import OutputsPage from 'pages/OutputsPage/OutputsPage';
import ProtectedRoute from './ProtectedRoute';
import FusionInstallersPage from 'pages/Installers/FusionInstallers';

export const routes = {
  index: {
    path: '/',
  },
  licensing: {
    path: '/licensing',
  },
  revitInstallers: {
    path: '/revit',
  },
  inventorInstallers: {
    path: '/inventor',
  },
  fusionInstallers: {
    path: '/fusion',
  },
  projectId: {
    path: '/projects/:projectId',
    id: 'projectId',
  },
  outputs: {
    path: 'outputs',
    id: 'outputsId',
  },
  models: {
    path: 'models',
  },
  instances: {
    path: 'instances',
  },
  review: {
    path: 'review',
  },
  releases: {
    path: 'releases',
  },
  folders: {
    path: 'folders/:folderUrn',
  },
  folderItems: {
    path: 'items/:itemUrn/:details?',
  },
};

export const nonProjectRoutes = [
  {
    path: routes.licensing.path,
    element: <LicensesPage />,
  },
  {
    path: routes.inventorInstallers.path,
    element: <InventorInstallersPage />,
  },
  {
    path: routes.revitInstallers.path,
    element: <RevitInstallersPage />,
  },
  {
    path: routes.fusionInstallers.path,
    element: <FusionInstallersPage />,
  },
];

export const routerConfig = [
  {
    path: routes.index.path,
    element: <App />,
    children: nonProjectRoutes,
  },
  {
    path: routes.projectId.path,
    element: <App />,
    id: routes.projectId.id,
    children: [
      {
        path: routes.outputs.path,
        element: (
          <PageContainer>
            <OutputsPage />
          </PageContainer>
        ),
        id: routes.outputs.id,
        children: [
          {
            path: routes.models.path,
            element: <ModelsPanel />,
            children: [
              {
                path: routes.folders.path,
                element: <ModelsPanel />,
                children: [
                  {
                    path: routes.folderItems.path,
                    element: <ModelsPanel />,
                  },
                ],
              },
            ],
          },
          {
            path: routes.instances.path,
            element: <InstancesPanel />,
          },
          {
            path: routes.review.path,
            element: <ReviewPanel />,
          },
        ],
      },
      {
        path: routes.releases.path,
        element: (
          <ProtectedRoute accessControlProperty="releasesTab" redirectPath={routes.outputs.id}>
            <PageContainer>
              <ReleasesPage />
            </PageContainer>
          </ProtectedRoute>
        ),
      },
    ],
  },
  // handle the unknown routes, just navigate to the root (404 case)
  {
    path: '*',
    element: <Navigate to={routes.index.path} replace />,
  },
];

export const router = createBrowserRouter(routerConfig);
