import {
  ConfirmationModal,
  ModalContext,
  NotificationsProvider,
  useModalStore,
  useNotificationStore,
} from '@mid-react-common/common';
import React from 'react';
import { FormPanel } from './FormPanel';

const FormPanelApp: React.FC = () => {
  const notificationStore = useNotificationStore();
  const modalStore = useModalStore();

  return (
    <ModalContext.Provider value={modalStore}>
      <ConfirmationModal />
      <NotificationsProvider store={notificationStore}>
        <FormPanel />
      </NotificationsProvider>
    </ModalContext.Provider>
  );
};

export default FormPanelApp;
