import Installers from 'components/Installers/Installers';
import {
  allFusionThumbnails,
  informedDesignFusionProductPageUrl,
  fusionInstallersBadge,
  fusionOnboardingPageUrl,
} from 'components/Installers/constants';
import { Addins } from 'mid-types';
import text from '../../global/text.json';

const FusionInstallersPage: React.FC = () => (
  <Installers
    badge={fusionInstallersBadge}
    allThumbnails={allFusionThumbnails}
    title={text.installers.fusion.title}
    information={text.installers.fusion.information}
    productPageUrl={informedDesignFusionProductPageUrl}
    productName={Addins.FUSION}
    onboardingCampaignUrl={fusionOnboardingPageUrl}
    // TODO: Remove when the product center pages are ready and when no longer in beta
    hideProductCenterPages
  />
);

export default FusionInstallersPage;
