import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ReleaseDetailsContainer = styled('div')`
  position: absolute;
`;

export const ReleaseDetailsTable = styled('div')`
  display: table;
  margin-top: ${({ theme }) => theme.var.marginBase}px;
  margin-left: ${({ theme }) => theme.var.marginBase}px;
  border-spacing: ${({ theme }) => theme.var.marginBase}px;
`;

export const ReleaseDetailsRow = styled('div')`
  display: table-row;
`;

export const ReleaseDetailsCell = styled('div')`
  display: table-cell;
`;

const ReleaseDetailsLabelBase = ({ noWrap = true, variant = 'label2', ...props }: TypographyProps) => {
  return <Typography noWrap={noWrap} variant={variant} {...props} />;
};

export const ReleaseDetailsLabel = styled(ReleaseDetailsLabelBase)`
  display: table-cell;
`;

const ReleaseDetailsValueBase = ({ noWrap = true, variant = 'body2', ...props }: TypographyProps) => {
  return <Typography noWrap={noWrap} variant={variant} {...props} />;
};

export const ReleaseDetailsValue = styled(ReleaseDetailsValueBase)`
  display: table-cell;
`;
