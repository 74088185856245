export default {
  releaseNotesCharactersCount: 'release-notes-characters-count',
  releaseNotesTextArea: 'release-notes-text-area',
};

export const emptyStateTestIds = {
  midEmptyStateContainer: 'mid-empty-state-container',
  midEmptyStateSvg: 'mid-empty-state-svg',
};

export const numericParameterCustomizationTestIds = {
  numericInputTooltipWrapper: 'numeric-input-tooltip-wrapper',
};

export const notificationTestIds = {
  notificationSnackbar: 'notification-snackbar',
};

export const summaryTableTestIds = {
  previewTable: 'preview-table',
  tableRow: 'table-row',
};

export const productPreviewTableTestIds = {
  releaseName: 'product-preview-release-name',
};

export const confirmationDialogTestIds = {
  confirmButton: 'confirmation-dialog-confirm-button',
  secondaryConfirmButton: 'confirmation-dialog-secondary-confirm-button',
  cancelButton: 'confirmation-dialog-cancel-button',
};

export const aboutInfoMenu = {
  aboutInfoButton: 'about-info-button',
  licensingPageLink: 'licensing-page-link',
  inventorInstallersPageLink: 'inventor-installers-page-link',
  revitInstallersPageLink: 'revit-installers-page-link',
  fusionInstallersPageLink: 'fusion-installers-page-link',
  termsAndConditionsPageLink: 'terms-and-conditions-page-link',
  informedDesignHelpPageLink: 'informed-design-help-page-link',
};

export const versionInformation = {
  closeButton: 'close-button',
};

export const nameAndRelease = {
  releaseName: 'release-name',
  releasesAndStatus: 'releases-and-status',
};

export const accBridge = {
  accBridgeOutgoingIcon: 'acc-bridge-outgoing-icon',
  accBridgeIncomingIcon: 'acc-bridge-incoming-icon',
  accBridgeTooltip: 'acc-bridge-tooltip',
  accBridgeTooltipLink: 'acc-bridge-tooltip-link',
  accBridgeTooltipLinkSeeDetails: 'acc-bridge-tooltip-link-see-details',
};
