import { AuthClientOptions, CacheLocation } from '@adsk/identity-web-sdk';
import { ENVIRONMENT } from 'mid-types';
import { getReactAppEnvironment } from 'mid-utils';

// Configuration for the ADSK Identity Web SDK
const identitySdkEnv =
  getReactAppEnvironment() === ENVIRONMENT.MOCK ||
  getReactAppEnvironment() === ENVIRONMENT.DEV ||
  getReactAppEnvironment() === ENVIRONMENT.STG
    ? ENVIRONMENT.STG
    : getReactAppEnvironment();

const cacheMethod: CacheLocation = 'sessionstorage';
export const identitySdkConfig: AuthClientOptions = {
  client_id: import.meta.env.VITE_AUTH_CONFIG_CLIENT_ID || 'CONFIGURE_IN_ENV',
  redirect_uri: window.location.origin + '/',
  redirect_uri_iframe: window.location.origin + '/silent-auth.html',
  useRefreshTokens: true,
  cacheLocation: cacheMethod,
  env: identitySdkEnv,
  scope:
    'user-profile:read user:read data:read data:write data:create data:search bucket:create bucket:read bucket:update bucket:delete account:read account:write',
};
