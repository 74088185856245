import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Link, { LinkProps } from '@mui/material/Link';

import LockIcon from '@mui/icons-material/Lock';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const releaseDetailsIconSize = 14;

export const IconWrapper = styled(Box)`
  margin-right: ${({ theme }) => theme.var.marginBase / 2}px;
`;

export const ReleaseDetailsIconButton = styled(IconButton)`
  padding: 0 0 0 ${({ theme }) => theme.var.marginBase / 2}px;
`;

export const ReleaseDetailsListItem = styled('li')`
  list-style-type: none;
`;

export const StaticParametersTooltipContent = styled(Box)`
  width: 200px;

  .mid-tooltip-link {
    display: block;
    margin-top: ${({ theme }) => theme.var.marginBase}px;
  }
`;

export const StaticReleaseParametersLockIcon = styled(LockIcon)`
  font-size: ${releaseDetailsIconSize}px;
`;

export const ReleaseNotesInfoIcon = styled(InfoOutlined)`
  font-size: ${releaseDetailsIconSize}px;
`;

const StaticParametersTooltipLinkBase = ({
  underline = 'none',
  variant = 'button',
  className = 'mid-tooltip-link',
  target = '_blank',
  rel = 'noopener noreferrer',
  ...props
}: LinkProps) => {
  return <Link underline={underline} variant={variant} className={className} target={target} rel={rel} {...props} />;
};

export const StaticParametersTooltipLink = styled(StaticParametersTooltipLinkBase)`
  font-size: inherit;
`;
